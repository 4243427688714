import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { PermissionService } from '@app/lib/services/permission/permission.service';
import type { MenuItem } from 'primeng/api';

import { RecordKeeperService } from '../../lib/services/record-keeper.service';
import { hasPermissionsFn } from './has-permissions.resolver';

export const mainTabsResolver: ResolveFn<MenuItem[] | undefined> = async () => {
    const permissionService = inject(PermissionService);
    const supportedRecordKeeper = inject(RecordKeeperService).recordKeeperUrl;

    const showPayrollActivityTab = await hasPermissionsFn(permissionService, 'LIST_PAYROLL');
    const showUpdateActivityTab = await hasPermissionsFn(permissionService, 'LIST_UPDATES');
    const showRecordKeepersTab = await hasPermissionsFn(permissionService, 'LIST_RECORD_KEEPER');

    const tabs: MenuItem[] = [
        {
            label: 'Plan Sponsors',
            routerLink: `/${supportedRecordKeeper}/plan-sponsors`
        },
        {
            label: 'Divisions',
            routerLink: `/${supportedRecordKeeper}/divisions`
        }
    ];

    if (showPayrollActivityTab) {
        tabs.push({
            label: 'Payroll Activity',
            routerLink: `/${supportedRecordKeeper}/payroll-activity`
        });
    }

    if (showUpdateActivityTab) {
        tabs.push({
            label: 'Deduction Updates',
            routerLink: `/${supportedRecordKeeper}/deduction-updates`
        });
    }

    if (showRecordKeepersTab) {
        tabs.push({
            label: 'Record Keepers',
            routerLink: `/${supportedRecordKeeper}/record-keepers`
        });
    }

    return tabs;
};
